@import 'responsive';
@import 'variableFontSize';

// Card Module
// -------------------------------------------------------------------------- //

.mat-card {
  font-family: inherit;
}

.card {
  &.mat-card {
    color: palette('zbrown', 'dark');
    background-color: palette('zgray', 'x-light');
    border-radius: 4px;
    padding: 2.5rem;
    @include for-phone-tablet {
      padding: 32px 16px;
    }
  }

  &.mat-card,
  &.mat-card:not([class*='mat-elevation-z']) {
    -webkit-box-shadow: 0 3px 5px rgba(palette('zbrown', 'x-dark'), 0.2);
    box-shadow: 0 3px 5px rgba(palette('zbrown', 'x-dark'), 0.2);
  }
}

// Card Collapsed
// -----------------------------------------------------------------------------

.card-collapsed {
  &.mat-card {
    padding: 0 !important;
  }
}

// Card Colors
// -----------------------------------------------------------------------------

.card-contrast {
  &.mat-card {
    background-color: palette('zbrown', 'mid-light');
  }

  &.mat-card,
  &.mat-card:not([class*='mat-elevation-z']) {
    -webkit-box-shadow: 0 3px 5px rgba(palette('zbrown', 'x-dark'), 0);
    box-shadow: 0 3px 5px rgba(palette('zbrown', 'x-dark'), 0);
  }
}

.card-contrast-light {
  &.mat-card {
    background-color: palette('zgray', 'light');
  }

  &.mat-card,
  &.mat-card:not([class*='mat-elevation-z']) {
    -webkit-box-shadow: 0 3px 5px rgba(palette('zbrown', 'x-dark'), 0);
    box-shadow: 0 3px 5px rgba(palette('zbrown', 'x-dark'), 0);
  }
}

// Card Shadow
// -----------------------------------------------------------------------------

.card-shadow {
  &.mat-card {
    background-color: rgba(palette('zbrown', 'mid-dark'), 0.1);
    padding: 1.25rem;
  }

  &.mat-card,
  &.mat-card:not([class*='mat-elevation-z']) {
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
  }
}

// Card danger
// -----------------------------------------------------------------------------
.card-danger {
  &.mat-card {
    background-color: rgba(palette('state', 'danger'), 0.3);
    padding: 1rem;
    color: darken(palette('state', 'danger'), 30%);
  }

  &.mat-card,
  &.mat-card:not([class*='mat-elevation-z']) {
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
  }
}

// Card Ghost
// -----------------------------------------------------------------------------

.card-ghost {
  &.mat-card {
    background-color: rgba(palette('zgray', 'x-light'), 0.5);
  }

  &.mat-card,
  &.mat-card:not([class*='mat-elevation-z']) {
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
  }
}

// Card No-Shadow
// -----------------------------------------------------------------------------

.card-no-shadow {
  &.mat-card {
    background-color: palette('zgray', 'x-light');
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
  }

  &.mat-card,
  &.mat-card:not([class*='mat-elevation-z']) {
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
  }
}

// Card no Overflow
// -----------------------------------------------------------------------------

.card-no-overflow {
  &.mat-card {
    overflow: visible !important;
  }
}

// Card Head Content
// -----------------------------------------------------------------------------

.card-head-content {
  &.mat-card {
    border-radius: 0;
    padding: 2.5rem 0 4rem 0;
    @media only screen and (max-width: 900px) {
      padding: 10px;
    }

    background-color: palette('zbrown', 'dark');
    color: palette('zgray', 'x-light');
  }

  &.mat-card,
  &.mat-card:not([class*='mat-elevation-z']) {
    -webkit-box-shadow: 0 0 0 transparent;
    box-shadow: 0 0 0 transparent;
  }
}

.card-head-content-nopadding {
  &.mat-card {
    padding: 2.5rem 0;
  }
}

// Card Splash
// -----------------------------------------------------------------------------

.card-splash-content {
  &.mat-card {
    margin-top: -3.75rem;
    padding: 3rem;
    width: 500px;
  }
}

@media (max-width: 576px) {
  .card-splash-content.mat-card {
    margin-top: 1rem;
  }
}

// Card Content
// -----------------------------------------------------------------------------

.card-content {
  &.mat-card {
    margin-top: -3.5rem;
    padding: 2.5rem;
    color: palette('zbrown', 'dark');
    @include for-phone-tablet {
      border-radius: 0;
      padding: 32px 16px;
      margin-top: 0;
    }
  }

  &.card-page {
    min-height: 16rem;
  }

  &.card-accordion {
    margin-top: -2rem;

    .mat-expansion-panel-header {
      height: 64px;
      padding-left: 2.5rem;
    }

    .mat-expansion-panel-body {
      padding: 0 2.5rem;
    }
  }
}

.card-content-subtitle {
  @extend %title-dark;
  margin-bottom: 1.5rem;
}

.card-content-no-negative {
  &.mat-card {
    margin-top: 0;
  }
}

.card-content-header {
  display: flex;
  margin-bottom: 3rem;
}
.card-content-header-space-title {
  display: flex;
  margin-bottom: 1.5rem;
  @include tablet {
    margin-bottom: 2.3rem;
  }
}
.card-content-header-title {
  @extend %title-dark;

  margin: 0;

  small {
    color: palette('zbrown', 'dark') !important;
    font-size: 0.9rem !important;
    @extend %font-regular;
  }
}

.card-content-header-action {
  float: right;
}

// Card Elements
// -----------------------------------------------------------------------------

.card-content-description {
  position: absolute;
  right: 3.5rem;

  > span {
    display: block;
    margin-bottom: 0.5rem;
    text-align: right;
  }
}

// Card Spacing
// -----------------------------------------------------------------------------

.card-spacing-small {
  &.mat-card {
    padding: 1.5rem;
  }
}

.card-spacing-large {
  &.mat-card {
    padding: 2.5rem;
  }
}

.card-no-spacing {
  &.mat-card {
    padding: 0;
  }
}

// -----------------------------------------------------------------------------

.card-medium-content {
  &.mat-card {
    margin-top: -3.75rem;
    padding: 2rem;
    width: 100%;
  }
}

// -----------------------------------------------------------------------------

.card-link-expiration {
  display: flex;
  padding: 24px 32px;
  align-items: center;
  gap: 32px;
  border-radius: 12px;
  background: var(--background-brand-secondary, #001150);
  margin-bottom: 32px;

  @media (max-width: 320px) {
    display: flex;
    width: 272px;
    padding: 24px;
    align-items: center;
    gap: 32px;
  }
}

.icon-link-expiration {
  width: 80px;
  height: 54.909px;
  display: flex;
  margin-right: 24px;

  @media (max-width: 320px) {
    width: 46px;
    height: 32px;
    margin-bottom: 24px;
  }
}

.card-body-link-expiration {
  display: flex;

  @media (max-width: 320px) {
    display: flex;
    flex-direction: column;
  }
}
